import gql from 'graphql-tag'

export const GET_APP_INIT_QUERY = gql`
	query getAppInit {
		appInit {
			tenant {
				name
				tenantCode
				apiDomain
				logo {
					file {
						fileUrl
					}
				}
				note
				timezone
			}
			tenantConfig {
				instagramUrl
				contactEmail
				facebookUrl
				faqsUrl
				linkedInUrl
				tiktokUrl
				twitterUrl
				youtubeUrl
				specialtyAssociateWithDiscipline
				enableOpusAiAssistant
			}
			mobileAppConfig {
				iosDownloadUrl
			}
			tenantContacts {
				contactType
				email
				phone
			}
			featureFlags {
				feature
				enabled
			}
		}
	}
`
