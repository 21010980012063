import {
	AlphabetSortAsc,
	AlphabetSortDesc,
	AlphabetSortHover,
	NumericSortAsc,
	NumericSortDesc,
	NumericSortHover,
	StandardSortAsc,
	StandardSortDesc,
	StandardSortHover,
} from '~/components/icons'
import { PATHS } from './path.constant'

export const HEADER_SORT_DATA_TYPE = {
	alphabet: 'alphabet',
	numeric: 'numeric',
	standard: 'standard',
	multiSelect: 'multi-select',
	other: 'other',
}

export const HEADER_SORT_DATA_TYPE_ICON = {
	[HEADER_SORT_DATA_TYPE.alphabet]: {
		hover: AlphabetSortHover,
		asc: AlphabetSortAsc,
		desc: AlphabetSortDesc,
	},
	[HEADER_SORT_DATA_TYPE.numeric]: {
		hover: NumericSortHover,
		asc: NumericSortAsc,
		desc: NumericSortDesc,
	},
	[HEADER_SORT_DATA_TYPE.standard]: {
		hover: StandardSortHover,
		asc: StandardSortAsc,
		desc: StandardSortDesc,
	},
}

export const JOB_SUBMISSION_STAGE = {
	newApplication: 'job_applied',
	submitted: 'internal_sub',
	exSubmitted: 'external_sub',
	scheduleInterview: 'schedule_interview',
	offered: 'offered',
	placed: 'placed',
	inReview: 'in_review',
	interview: 'interview',
}
export const JOB_SUBMISSION_STAGE_BADGE_COLOR = {
	[JOB_SUBMISSION_STAGE.newApplication]: {
		textColor: '#7E7E80',
		bgColor: '#F5F5F5',
	},
	[JOB_SUBMISSION_STAGE.scheduleInterview]: {
		textColor: '#F29100',
		bgColor: '#FFF6DE',
	},
	[JOB_SUBMISSION_STAGE.submitted]: {
		textColor: '#0F58E4',
		bgColor: '#EFF4FF',
	},
	[JOB_SUBMISSION_STAGE.exSubmitted]: {
		textColor: '#0F58E4',
		bgColor: '#EFF4FF',
	},
	[JOB_SUBMISSION_STAGE.offered]: {
		textColor: '#37AA00',
		bgColor: '#ECFDF3',
	},
	[JOB_SUBMISSION_STAGE.placed]: {
		textColor: '#37AA00',
		bgColor: '#ECFDF3',
	},
	[JOB_SUBMISSION_STAGE.inReview]: {
		textColor: '#0F58E4',
		bgColor: '#EFF4FF',
	},
	[JOB_SUBMISSION_STAGE.interview]: {
		textColor: '#0F58E4',
		bgColor: '#EFF4FF',
	},
}

export const EMPTY_CELL_DATA = 'N/A'
export const CANDIDATE_PROFILE_COMPLETED = 100

export const redirectTabViewAsCandidate = (worker) => {
	if (worker?.profilePercentageCompleted === CANDIDATE_PROFILE_COMPLETED) {
		return `${PATHS.recruiter.view_as_candidate}/${worker.id}/PROFILE`
	}
	return `${PATHS.recruiter.view_as_candidate}/${worker.id}/ACTIVATION`
}

export const LIMIT_DATA_INNER_TABLE = 10
