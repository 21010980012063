import React, { Suspense } from 'react'
import { Route as BaseRoute, Redirect } from 'react-router-dom'
import { observer } from '~/common/mobx.decorator'
import { authStore, routerStore } from '~/stores'
import { Error403 } from '~/components/errors'
import { Backdrop, Box, CircularProgress, Zoom } from '@material-ui/core'
import { PATHS } from '~/common/constants'

const RouteComponent = observer(({ auth, feature, component: Component, transition: Transition, ...props }) => {
	if (!Component) {
		return <Redirect to={PATHS.common.home} />
	}

	if (auth) {
		if (!authStore.authorization) {
			return <Redirect to={{ pathname: PATHS.common.signin, state: { from: routerStore.from } }} />
		}

		if (feature && !authStore.features?.includes(feature)) {
			return <Error403 />
		}
	}

	if (authStore.authorization && props.match.path === PATHS.common.reset) {
		return <Redirect to={{ pathname: PATHS.common.home, state: {} }} />
	}

	if (authStore.authorization && props.match.path === PATHS.common.signin) {
		return <Redirect to={{ pathname: PATHS.common.home, state: {} }} />
		//return <Redirect to={{ pathname: props?.location?.state?.from?.pathname || PATHS.common.home, state: { from: routerStore.from } }} />
	}

	return (
		<Suspense
			fallback={
				<Backdrop open={true}>
					<CircularProgress />
				</Backdrop>
			}
		>
			{Transition ? (
				<Transition in>
					<Box>
						<Component {...props} />
					</Box>
				</Transition>
			) : (
				<Component {...props} />
			)}
		</Suspense>
	)
})

export const Route = ({ auth, feature, component, transition, ...props }) => (
	<BaseRoute {...props} render={(rest) => <RouteComponent {...rest} component={component} auth={auth} feature={feature} transition={transition} />} />
)

Route.defaultProps = {
	transition: Zoom,
}
