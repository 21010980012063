import { gql } from '@apollo/client'
import { MAESTRO_EMPLOYER_FRAGMENT } from '../apollo.fragment'

export const SIGNIN_BULLHORN_RECRUITER_MUTATION = gql`
	mutation signInBullhornRecruiter($payload: String!) {
		signInBullhornCustomComponent(payload: $payload) {
			authToken {
				accessToken
				expiresIn
				refreshToken
				tokenType
			}
			notificationChannel
			employer {
				...maestroEmployerFragment
			}
			entity {
				modelName
				fieldValue
				fieldName
			}
		}
	}

	${MAESTRO_EMPLOYER_FRAGMENT}
`

export const ACCEPT_AUTO_SUBMISSION_MUTATION = gql`
	mutation acceptAutoSubmission($token: String!, $jobIds: [String!]!, $confirmedSource: AutoSubmissionConfirmedSourceEnum) {
		acceptAutoSubmission(token: $token, jobIds: $jobIds, confirmedSource: $confirmedSource) {
			autoSubmissionToken {
				token
				status
			}
		}
	}
`

export const DECLINE_AUTO_SUBMISSION_MUTATION = gql`
	mutation declineAutoSubmission($token: String!, $jobIds: [String!]!, $confirmedSource: AutoSubmissionConfirmedSourceEnum) {
		declineAutoSubmission(token: $token, jobIds: $jobIds, confirmedSource: $confirmedSource) {
			autoSubmissionToken {
				token
				status
			}
		}
	}
`

export const FEEDBACK_AUTO_SUBMISSION_MUTATION = gql`
	mutation feedbackAutoSubmission($token: String!, $jobIds: [String!]!, $feedbackContent: AutoSubmissionFeedbackContentInput!) {
		feedbackAutoSubmission(token: $token, jobIds: $jobIds, feedbackContent: $feedbackContent) {
			success
		}
	}
`

export const REFRESH_TOKEN = gql`
	mutation refreshToken($refreshToken: String!) {
		refreshToken(refreshToken: $refreshToken) {
			authToken {
				accessToken
				refreshToken
				tokenType
				expiresIn
			}
		}
	}
`

export const RECRUITER_CHANGE_PASSWORD = gql`
	mutation recruiterChangePassword($password: String!, $passwordConfirmation: String!) {
		recruiterChangePassword(password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const UPDATE_EMPLOYER_PASSWORD = gql`
	mutation updateEmployerPassword($password: String) {
		updateEmployer(password: $password) {
			id
		}
	}
`

export const RECRUITER_PROFILE_CHANGE_PASSWORD = gql`
	mutation employerChangePassword($oldPassword: String!, $password: String!, $passwordConfirmation: String!) {
		employerChangePassword(oldPassword: $oldPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
			authToken {
				accessToken
				refreshToken
				tokenType
				expiresIn
			}
		}
	}
`

export const RECRUITER_UPDATE_PROFILE = gql`
	mutation recruiterUpdateRecruiter($id: String!, $firstName: String, $lastName: String, $email: String, $title: String) {
		recruiterUpdateRecruiter(id: $id, firstName: $firstName, lastName: $lastName, email: $email, title: $title) {
			id
		}
	}
`

export const SIGNOUT_RECRUITER_MUTATION = gql`
	mutation employerSignOutMutation {
		employerSignOut {
			success
		}
	}
`
